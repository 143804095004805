import React, { useState ,useEffect}from 'react'
import NavBar from '../NavBar/NavBar'
import {logoPngImg} from '../../Config.js'
import Login from './Login/Login'
import SignUp from './SignUp/SignUp'
import {useSelector} from "react-redux"
import { useNavigate } from 'react-router-dom';

function LoginAndSignupContainer() {
    
  const authenticated = useSelector(state => state.userAuthentication.authenticated)

  const navigate=useNavigate()
  const [showLogin, setShowLogin] = useState(true)
  const [showOtp, setShowOtp] = useState(false)

  //mobile
  const [mobile, setMobile] = useState("")


  const showTheLogin=(pass)=>{
    setShowLogin(pass)
  }  

  const showTheOtp=(pass)=>{

    setShowOtp(pass)
  }  


  const passMobile=(pass)=>{
    setMobile(pass)
  }


  useEffect(() => {
  
    if(authenticated===true)
    {
      navigate('/')
    }

   
    
  
  }, [])

  return (
    <>

    <NavBar/>
                    
    
    <div  className="d-flex flex-column min-vh-100 justify-content-center align-items-center" 
          style={{backgroundColor: "#eeeeee"}  }>

      <div className="container col-xl-10 col-lg-10 col-12 bg-light shadow p-3 mb-5 bg-body rounded">

      <div className="row p-5 align-items-center  ">
                <div className="col-xl-5 col-md-6 col-lg-6  col-12"><img src={logoPngImg} style={{maxWidth: "100%",maxHeight: "100%"}} alt="logo" /></div>




      {showLogin? <Login showOtp={showOtp} status={{showTheLogin,showTheOtp,passMobile}}   />:<SignUp  mobile={mobile}  />}
          
      </div>


      </div>
  </div>


</>
  )
}

export default LoginAndSignupContainer