import {createSlice } from "@reduxjs/toolkit"

export const isLoadingSlice= createSlice ({
    name : "isLoading",
    initialState :{
        isLoading:false,
        
    },

    reducers:{

        set_isLoading : (state,{payload})=>{
            state.isLoading=payload.isLoading     
        },
      
    }
})

export const {set_isLoading} = isLoadingSlice.actions

export default isLoadingSlice.reducer