import React, { useEffect, useState } from 'react'
import  {CloudArrowUpFill} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from "react-redux"
import axios from 'axios';
import {baseURL,flyerImagePath,NoFlyerAddedImg} from '../../../Config';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RangeSlider from 'react-bootstrap-range-slider';
import {set_isLoading} from "../../../Redux/isLoading/isLoadingSlice"
import HashLoader from "react-spinners/HashLoader";


// Import Swiper React components
import { Swiper, SwiperSlide  } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

function OldUserMiddleContainer() {
    const navigate = useNavigate();
    const [ planDuration, setPlanDuration ] = useState(1); 
    const dispatch =useDispatch()
    const isLoading = useSelector(state => state.isLoading.isLoading);


    const getPlanDuration =(end_date)=> {
        var dt1 = new Date();
        var dt2 = new Date(end_date);
        
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
        }

    const admin_approved_vendor_payment = useSelector(state => state.userDetails.admin_approved_vendor_payment);
    const vendor_plan_status = useSelector(state => state.planDetails.vendor_plan_status);
    const no_of_flyers = useSelector(state=> state.planDetails.no_of_flyers);
    const plan_end_date = useSelector(state => state.planDetails.end_date)

    const access_token = JSON.parse(localStorage.getItem('access_token'));

    const addNewFlyer = ()=>{ 
        
        if(admin_approved_vendor_payment==="0")
        {
            alert("Your Payment Not Approved By Administrator , Approval May Take Upto 1 Hour - Please Wait !")
        }
        else if (vendor_plan_status==="inactive")
        {
            alert("Please Make Sure You Activated The Plan, To Add Flyers")
            navigate('/myplans') 
        }

        else
        {
            navigate('/addnewflyer') 
        }

        }

    //get flyer list of a user
    const getFlyerListURL='api/vendor/flyer/list'
    const [fLyerList, setfLyerList] = useState(null);

    const getFlyerList=async()=>{
        dispatch(set_isLoading({isLoading:true}))
    try {
        const res = await axios.get(baseURL+getFlyerListURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {
            
            setfLyerList(res.data.data);
            dispatch(set_isLoading({isLoading:false}))
        }
        else{
            alert("Error Contact Administrator")
        }
    } catch (error) {
        return( error)
    }
    }


    //launch modal
    
    const [show, setShow] = useState(false);

    const handleClose = () =>{ setShow(false);getFlyerList()}
    const handleShow = () => setShow(true);


    //alert box DEACTIVATE
    const [showAlertBoxDeactivate, setShowAlertBoxDeactivate] = useState(false);
    const handleCloseAlertBoxDeactivate = () => setShowAlertBoxDeactivate(false);
    const handleShowAlertBoxDeactivate = () => setShowAlertBoxDeactivate(true);

    //alert box DEACTIVATE
    const [showAlertBoxDelete, setShowAlertBoxDelete] = useState(false);
    const handleCloseAlertBoxDelete = () => setShowAlertBoxDelete(false);
    const handleShowAlertBoxDelete = () => setShowAlertBoxDelete(true);


//get details of each flyer
const getFlyerDetailsURL='api/vendor/flyer/details'
const [flyerDetails, setFlyerDetails] = useState(null);

    const getFlyerDetails=async(flyer_id)=>{
        handleShow()
    try {
        const res = await axios.post(baseURL+getFlyerDetailsURL ,{flyer_id:flyer_id }, { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {
            setFlyerDetails(res.data.data);
            setVendorFlyerStatus(res.data.data.status)
        }
        else{
            alert("Error Contact Administrator")
        }
    } catch (error) {
        return( error)
    }
    }


//get details of each flyer
const changeFlyerStatusURL='api/vendor/flyer/status/change'
const [vendorFlyerStatus, setVendorFlyerStatus] = useState(null);

const changeFlyerStatus=async(flyer_id,duration)=>{
    
    try {
        const res = await axios.post(baseURL+changeFlyerStatusURL ,{flyer_id:flyer_id,duration:duration }, { headers: {"Authorization" : `Bearer ${access_token}`} })
       
        if(res.data.success===true)
        {
            setVendorFlyerStatus(res.data.data.status);
            handleCloseAlertBoxDeactivate()
        }
        else if (res.data.success===false)
        {
            alert(res.data.message)
        }
        else{
            alert("Error Contact Administrator")
        }
    } catch (error) {
        return( error)
    
    }
}

//delete flyer


const deleteFlyerURL='api/vendor/flyer/delete'
const deleteFlyer=async(flyer_id)=>{
    
    try {
        const res = await axios.post(baseURL+deleteFlyerURL ,{flyer_id:flyer_id}, { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success===true)
        {
             handleCloseAlertBoxDelete();
             handleClose();
        }
        else if (res.data.success===false)
        {
            alert(res.data.message)
        }
        else{
            alert("Error Contact Administrator")
        }
       

    } catch (error) {
        return( error)
    
    }
}

    
useEffect(() => {
  
    getFlyerList()


}, [])

    
  return (
    <>
     {isLoading?<div className={isLoading ? 'parentDisableCommon' : ''} width="100%">
      <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
    </div>:null}

    <div className='row p-4 mt-2 text-secondary'>

            <div className="d-flex justify-content-between ">
                <div ><h6 style={{fontWeight:"500"}}> Flyers Added </h6> </div>
                
                {/* <div ><h6 style={{fontWeight:"500",fontSize:'0.8em'}}> <u>See More</u> </h6> </div> */}
            </div>


    </div>   

 {/* flyer creation section */}

    <div className="container-fluid  col-12">
        <div className='row justify-content-center'>

            <div className='bg-light p-5 col-xl-3 col-lg-3 mt-1 text-center  '>
                <CloudArrowUpFill size={48} color='#6c757d'/>
               <div className='row'> <button className='btn btn-primary'  onClick={addNewFlyer} > + Add New Flyer</button></div>
            </div>

            {fLyerList?(fLyerList.length!==0)?

            <div className='bg-light col-xl-9 col-lg-9 col-12 mt-1 text-center  '>      
            <Swiper slidesPerView={1} spaceBetween={20} breakpoints= {{
                // when window width is >= 320px
                320: {
                slidesPerView: 1,
                spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                slidesPerView: 1,
                spaceBetween: 20
                },
                // when window width is >= 640px
                640: {
                slidesPerView: 2,
                spaceBetween: 20
                }
                }}
                pagination={{
                clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
              {fLyerList.map((Flyer,index)=>
                            {
                            return(
                            <SwiperSlide key={index}>
                                <div className="card col-12" >
                                <img src={`${flyerImagePath}${Flyer.thumb_image}`} className="card-img-top" alt="img" style={{maxWidth:"100%"}}/>
                                    <div className="card-body">
                                        <h5 className="card-title">{Flyer.title}</h5>
                                        <span className="card-text">Flyer Status : {(Flyer.admin_approved==="1")?(Flyer.status==="active")?<span style={{color:"green"}}>Active</span>:<span style={{color:"brown"}}>inactive</span>:<span style={{color:"orange"}}>Pending</span>} </span>
                                        
                                        {(Flyer.status==="active")?<p>Duration :<span style={{color:"brown"}}> {getPlanDuration(Flyer.end_date)} Days Left</span>  </p>:
                                        <p>Duration :<span style={{color:"red"}}> Expired</span>  </p>}

                                        <button  className="btn btn-primary mb-3" value={Flyer.id} onClick={()=>getFlyerDetails(Flyer.id)} >View Details</button>
                                    </div>
                                </div>
                            </SwiperSlide>
                            )
                            }
            )}
            </Swiper>
        </div>:            
        <div className='col-7 p-3 d-flex align-items-stretch justify-content-center'>
          <div className="d-flex flex-column">
            <div className="p-2"><img src={NoFlyerAddedImg} alt={NoFlyerAddedImg} style={{maxWidth:'100%',maxHeight:'450px'}}/></div>
            <div className="p-2 text-center"> <h5>No Flyers Added !  </h5></div>
          </div>
    
        </div>
        :<></>}


            
    </div>
</div>

{/* //modal */}
{flyerDetails?
<Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Title : {flyerDetails.title}
           <p className='p-0 m-0 text-break' style={{fontSize:'0.9rem',fontWeight:300}}>Description : {flyerDetails.description}</p> 
           <p className='p-0 m-0' style={{fontSize:'0.9rem',fontWeight:300}}>Flyer Status : {(flyerDetails.admin_approved==="1")?(vendorFlyerStatus==="active")?<span style={{color:"green"}}>Active</span>:<span style={{color:"brown"}}>inactive</span>:<span style={{color:"orange"}}>Pending</span>} </p>
           {(vendorFlyerStatus==="active")?
           <>
           <p className='p-0 m-0' style={{fontSize:'0.9rem',fontWeight:300}}>Start Date : {flyerDetails.start_date}  </p>
           <p className='p-0 m-0' style={{fontSize:'0.9rem',fontWeight:300}}>End Date : {flyerDetails.end_date}  </p>
           </>:null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='row'>
            <div className='col-xl-6 col-lg-6 text-center  '>
            <img src={`${flyerImagePath}${flyerDetails.thumb_image}`} alt="img"  style={{maxWidth:"100%"}}/>
            <p className='mt-2'>Thumbnail Image</p>
            </div>

            {flyerDetails.flyers?(flyerDetails.flyers.length!==0)?

            <div className='col-xl-6 col-lg-6 col-12  text-center '>      
                <Swiper slidesPerView={1} spaceBetween={20} breakpoints= {{
                    // when window width is >= 320px
                    320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                    },
                    // when window width is >= 480px
                    480: {
                    slidesPerView: 1,
                    spaceBetween: 20
                    },
                    // when window width is >= 640px
                    640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                    }
                    }}
                    pagination={{
                    clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiperImages"
                >
                {flyerDetails.flyers.map((Flyer,index)=>
                         {
                        return(
                                
                                <SwiperSlide key={index}>
                                    <div className="" >
                                    <img src={`${flyerImagePath}${Flyer.flyers}`} alt="img"  style={{maxWidth:"100%"}}/>
                                    </div>
                                </SwiperSlide>
                               

                               
                                )
                                }
                )}
                </Swiper>
                <p className='mt-2'>Additional Images</p>
                </div>:<>
                Sorry No Flyer Added</>:<></>}
                        </div>
                        
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={handleShowAlertBoxDelete}>
                            Delete
                        </Button>

                        
                        {vendorFlyerStatus==='active'?<>
                        <Button className='btn-warning' onClick={handleShowAlertBoxDeactivate}>Deactivate</Button>
                        {/* <Button className='btn-warning' onClick={()=>{changeFlyerStatus(flyerDetails.id,'')}}>Deactivate</Button> */}

                        
                        </>:
                        <>  
                           
                               
                                <label className='formLabelNew' >Duration Of Flyer</label>
                                <RangeSlider
                                value={planDuration} min={1} max={getPlanDuration(plan_end_date)} size={'lg'} tooltip={'off'}
                                onChange={changeEvent => setPlanDuration(changeEvent.target.value)}
                                />
                                <p>{planDuration} Day </p>

                                <Button variant="success" onClick={()=>{changeFlyerStatus(flyerDetails.id,planDuration)}}>Activate</Button>
                             

                                
                        
                        </>}

                       {/* <Button variant={vendorFlyerStatus==='active'?"danger":"primary"}    {vendorFlyerStatus==='active'? {onClick={()=>changeFlyerStatus(flyerDetails.id)}}} :alert("deactive")        >{(vendorFlyerStatus==='active')?<>Deactivate</>:<>Activate</>}    </Button> */}

                        {/* <Button variant={vendorFlyerStatus==='active'?"danger":"primary"} onClick={()=>changeFlyerStatus(flyerDetails.id)}  >{(vendorFlyerStatus==='active')?<>Deactivate</>:<>Activate</>}    </Button> */}
                        </Modal.Footer>
                </Modal>:null}

                

      <Modal
        show={showAlertBoxDeactivate}
        onHide={handleCloseAlertBoxDeactivate}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       Do You want to deactive this flyer ? You can re-activate if needed
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlertBoxDeactivate}>
            Close
          </Button>
          <Button variant="warning" onClick={()=>{changeFlyerStatus(flyerDetails.id,'')}}>Deactivate</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAlertBoxDelete}
        onHide={handleCloseAlertBoxDelete}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       Do You want to delete this flyer ? You cannot get it back
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlertBoxDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={()=>deleteFlyer(flyerDetails.id)}>Delete</Button>
        </Modal.Footer>
      </Modal>
      
      
    </>
  )
}

export default OldUserMiddleContainer