import React, { useEffect, useState } from 'react'
import {logoBlue,baseURL} from '../../../Config.js';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import './Login.css';
import {authenticate_user,unauthenticate_user} from "../../../Redux/userAuthentication/userAuthenticationSlice"
import {remove_user_details} from "../../../Redux/userDetails/userDetailsSlice"
import {remove_planDetails} from "../../../Redux/planDetails/planDetailsSlice"
import {set_isLoading} from "../../../Redux/isLoading/isLoadingSlice"
import HashLoader from "react-spinners/HashLoader";


import {useDispatch, useSelector} from "react-redux"
import CustomerToVendor from '../CustomerToVendor/CustomerToVendor.js';

function Login({showOtp,status}) {



const authenticated = useSelector(state => state.userAuthentication.authenticated)
const isLoading = useSelector(state => state.isLoading.isLoading);

const loginURL = 'api/vendor/login'
const dispatch =useDispatch()

const {
  register,
  handleSubmit,
  formState: { errors },
} = useForm({
  mode: "all",
});

//mobile number
const [customerToVendor, setCustomerToVendor] = useState(false)

//mobile number
const [mobile, setMobile] = useState("")

//geting customer data from api
const [customer, setCustomer] = useState(0)

//make buton login or send api reque
const [makeBtnLogin, setMakeBtnLogin] = useState(false)

//show error message
const [disableError, setDisableError] = useState(true)

//error message display 
const [message, setMessage] = useState("")


//navigate to
const navigate = useNavigate();

//SIGNUP
const onSubmit = (data) => {

  //data getting from form
  setMobile(data.mobile)
  if(makeBtnLogin)
  {
    dispatch(set_isLoading({isLoading:true}))
    axios
    .post(baseURL+loginURL, {
      mobile: data.mobile,
      otp:data.otp,
      })
    .then((response) => {
      if(response.data.success)
      {
        dispatch(set_isLoading({isLoading:false}))
        localStorage.setItem('access_token', JSON.stringify(response.data.data.token));
        dispatch(authenticate_user({name:""}))
        dispatch(remove_user_details())
        dispatch(remove_planDetails())
        navigate("/");
        navigate(0);

      }

      else
      {
        dispatch(set_isLoading({isLoading:false}))
        setDisableError(false)
        dispatch(unauthenticate_user())
        setMessage(response.data.message);
      }
     
    });

  }
  else
  {
    dispatch(set_isLoading({isLoading:true}))
      axios
    .post(baseURL+loginURL, {
      mobile: data.mobile,
      otp:data.otp,
      }
      )
    .then((response) => {
      dispatch(set_isLoading({isLoading:false}))
      if(response.data.message==='customer')
      {
        console.log("customer");
        setCustomerToVendor(true);
        
      }
      else if (response.data.data===null){
        setMessage(response.data.message);
        setDisableError(false)
      }
      else
      {
        setCustomer(response.data.data);
        setMakeBtnLogin(response.data.success);
    
      }

      
    }).catch(error => {
      dispatch(set_isLoading({isLoading:false}))
      setDisableError(false)
      setMessage(error.response.data.message);
      console.log(error.response)
   })  
  }
}









useEffect(() => {


  
 if(authenticated===true)
    {
      navigate('/')
    }

  
  if(customer.verified===1)
  {
   status.showTheOtp(true)
   setMakeBtnLogin(true)
    
  }
  else if (customer.verified===0)
  {
    status.showTheLogin(false)
    status.passMobile(mobile)
  
  }
  else {
    console.log('');
  }
 
  

}, [customer,status,mobile])

  return (
   <>
    
    {isLoading?<div className={isLoading ? 'parentDisableLight' : ''} width="100%">
      <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
    </div>:null}

   <div className="col-xl-7 col-md-6 col-lg-6  col-12">

<div className="d-flex flex-wrap bd-highlight align-items-center">

    <div > <h2  className='mb-0' style={{fontWeight:"600", color:"#676767",fontSize:"1.9rem"}}  >Login/Signup</h2> </div>

    <div ><img src={logoBlue} style={{maxWidth: "100%"}} alt="logo" /></div>
  </div>

        <div className='mt-3'>
  
        <form onSubmit={handleSubmit(onSubmit)}>
       {showOtp?

          //otp
          <>

          <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Mobile Number</label>
              <div className="form-group has-icon mb-2">
              <span className="form-control-feedback">+91</span>
              <div className="form-control rounded-0 paddingonLoginandSignUp">
              {mobile} <span onClick={()=>{status.showTheOtp(false);setCustomer(0);setMakeBtnLogin(false);}} style={{color:'red',fontSize:'0.7rem'}}>Change</span> </div>
          </div>

         <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Enter OTP</label>
         <div className="form-group has-icon mb-2">
         <span className="form-control-feedback">OTP</span>
         <input
           maxLength={6}
           onKeyPress={ (e)=>setDisableError(true)}
           className="form-control rounded-0 paddingonLoginandSignUp" 
         {...register("otp", {
           required: "Please Enter OTP",
           minLength: {
             value: 6,
             message: "Enter 6 Digit OTP",
           },
          
         })}
         placeholder="_ _ _ _ _ _"
       />
     <p className='m-0' style={{color:"red"}}>{errors.otp?.message}</p>
     
     </div>
     </>
     :
<>
    <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Mobile Number</label>
         <div className="form-group has-icon mb-2">
         <span className="form-control-feedback">+91</span>
         <input
            onKeyPress={(event) => {
             setDisableError(true);
             if (!/[0-9]/.test(event.key)) {
               event.preventDefault();
             }
           }}
           maxLength={10}
          

           className="form-control rounded-0 paddingonLoginandSignUp" 
         {...register("mobile", {
           required: "mobile is Required...",
           minLength: {
             value: 10,
             message: "Please Enter 10 Digit Mobile Number",
           },
          
         })}
         placeholder="xxx - xx - xxx"
       />
     <p className='m-0' style={{color:"red"}}>{errors.mobile?.message}</p>
     </div>  </> } 

       {!disableError?<span style={{color:"red"}}> {message}  </span>:null     }  



        <input type='submit' className='btn btn-primary btn-lg w-100 rounded-0 p-2 shadow ' value="Login As Vendor" />
        </form> 

        {customerToVendor&&<CustomerToVendor />} 

        </div>
        <p className='text-secondary text-center mt-3 text-break ' style={{fontSize:'0.7rem'}}>By Continuing you agree to our Terms And Condtions and Privacy & Legal Policy |
          <a href="mailto:support@iwillfly.com?subject=Contact Us" className="ms-1">
          Contact Us
          </a></p>
        </div>
       
   
   </>
  )
}

export default Login