import {createSlice } from "@reduxjs/toolkit"

export const planDetailsSlice= createSlice ({
    name : "planDetails",
    initialState :{
        plan_id:null,
        name:null,
        duration:null,
        no_of_flyers:null,
        no_of_images_per_flyers:null,
        start_date:null,
        end_date:null,
        real_start_date:null,
        real_end_date:null,
        plan_status:null,
        payment_status:null,
        vendor_plan_status:null,
        payment_approval:null,
        order_id:null




    },

    reducers:{

        set_planDetails : (state,{payload})=>{
            state.plan_id=payload.plan_id
            state.name=payload.name
            state.duration=payload.duration
            state.no_of_flyers=payload.no_of_flyers
            state.no_of_images_per_flyers=payload.no_of_images_per_flyers
            state.start_date=payload.start_date
            state.end_date=payload.end_date
            state.real_start_date=payload.real_start_date
            state.real_end_date=payload.real_end_date
            state.plan_status=payload.plan_status
            state.payment_status=payload.payment_status
            state.vendor_plan_status=payload.vendor_plan_status
            state.payment_approval=payload.payment_approval
            state.order_id=payload.order_id
        },



        remove_planDetails : (state)=>{
            state.plan_id=null
            state.name=null
            state.duration=null
            state.no_of_flyers=null
            state.no_of_images_per_flyers=null
            state.start_date=null
            state.end_date=null
            state.real_start_date=null
            state.real_end_date=null
            state.plan_status=null
            state.payment_status=null
            state.vendor_plan_status=null
            state.payment_approval=null
            state.order_id=null
        },




      
    }
})

export const {set_planDetails,remove_planDetails} = planDetailsSlice.actions

export default planDetailsSlice.reducer