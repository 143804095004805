import {configureStore } from "@reduxjs/toolkit"
import userAuthenticationReducer from "./userAuthentication/userAuthenticationSlice"
import userDetailsReducer from "./userDetails/userDetailsSlice"
import planDetailsReducer from "./planDetails/planDetailsSlice"
import tempDataReducer from "./tempData/tempDataSlice"
import isLoadingReducer from "./isLoading/isLoadingSlice"
export default configureStore({
    reducer:{
        userAuthentication:userAuthenticationReducer,
        userDetails:userDetailsReducer,
        planDetails:planDetailsReducer,
        tempData:tempDataReducer,
        isLoading:isLoadingReducer
    }
})