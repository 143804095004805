import React, { useEffect } from 'react'
import {  useSearchParams,useNavigate  } from 'react-router-dom';
import {baseURL} from '../../Config';
import axios from 'axios';
import HashLoader from "react-spinners/HashLoader";
function PaymentSuccess() {

  const navigate =useNavigate ();
  const [searchParams] = useSearchParams();
  const paymentStatus = searchParams.get('payment_status');
  const saveUserPaymentStatusURL ='api/vendor/plan/save'
  const access_token = JSON.parse(localStorage.getItem('access_token'));
  const ORDER_ID = JSON.parse(localStorage.getItem('buyerTempID'));

  const saveUserPaymentStatusSuccess= async()=>
  {
    try 
    {
        const res = await axios.post(baseURL+saveUserPaymentStatusURL ,{order_id:ORDER_ID ,payment_status:'paid',payment_id:searchParams.get('payment_id')}, { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
         {
          localStorage.removeItem('buyerTempID');
          navigate(
            '/payment_confirmation',{state: {
                'paymentStatus':true,
                'paymentID':searchParams.get('payment_id')
              }});
         }
        else
        {
          alert("contact admin")
        }
    } catch (error) 
    {
        alert(error)
    }
  
  }

  const saveUserPaymentStatusFailed= async()=>
  {
    try 
    {
        const res = await axios.post(baseURL+saveUserPaymentStatusURL ,{order_id:ORDER_ID ,payment_status:'notpaid',payment_id:searchParams.get('payment_id')}, { headers: {"Authorization" : `Bearer ${access_token}`} })
    
        if(res.data.success)
         {
          localStorage.removeItem('buyerTempID');
          navigate(
            '/payment_confirmation',{state: {
                'paymentStatus':false,
                'paymentID':searchParams.get('payment_id')
              }})
         }
        else
        {
          alert("contact admin")
        }
    } 
    catch (error) 
    {
        alert(error)
    }
  
  }




  useEffect(() => {
    if (paymentStatus==="Credit" )
    {
      saveUserPaymentStatusSuccess()
    }
    else
    {
      saveUserPaymentStatusFailed()
    }

  },[paymentStatus] )
  
  return (
    
   
    
    <>
  <HashLoader color={'#0d6efd'} loading={true} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />

    </>
  )
}

export default PaymentSuccess