import {createSlice } from "@reduxjs/toolkit"

export const userAuthenticationSlice= createSlice ({
    name : "userAuthentication",
    initialState :{
        name:"",
        authenticated:null,
        status:null
    },

    reducers:{

        authenticate_user : (state,{payload})=>{
            state.name=payload.name
            state.authenticated = true
            state.status = "success"
        },

        unauthenticate_user : (state,action)=>{
            state.name=""
            state.authenticated = false
            state.status = "failed"
        },

        inisate_user : (state,action)=>{
            state.status = null
            state.authenticated = null
            state.name = ""
        }
    }
})

export const {authenticate_user,unauthenticate_user,inisate_user} = userAuthenticationSlice.actions

export default userAuthenticationSlice.reducer