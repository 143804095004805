import {createSlice } from "@reduxjs/toolkit"

export const tempDataSlice= createSlice ({
    name : "tempData",
    initialState :{
        plan_id:null,
        
    },

    reducers:{

        set_temp_plan_Id : (state,{payload})=>{
            state.plan_id=payload.plan_id
           
        },



        remove_temp_plan_Id : (state)=>{
            state.plan_id=null
        },




      
    }
})

export const {set_temp_plan_Id,remove_temp_plan_Id} = tempDataSlice.actions

export default tempDataSlice.reducer