import React, { useEffect, useState } from 'react'
import GeneralMenu from '../Home/GeneralMenu/GeneralMenu'
import  {CloudArrowUpFill,CloudPlusFill} from 'react-bootstrap-icons';
import './AddNewFlyer.css'
import { useForm ,Controller} from "react-hook-form";
import {baseURL,flyerDetailsPDFFILE} from '../../Config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux"
import RangeSlider from 'react-bootstrap-range-slider';
import HashLoader from "react-spinners/HashLoader";
import CopyRightAndTerms from '../CopyRightAndTerms/CopyRightAndTerms';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

function AddNewFlyer() {
  
  const [ planDuration, setPlanDuration ] = useState(1); 
  const [isLoading, setIsLoading] = useState(false);
  const addNewFlyerURL='api/vendor/flyers/add'
  const access_token = JSON.parse(localStorage.getItem('access_token'));
  const anyPlan_plan_id = useSelector(state=> state.userDetails.anyPlan_plan_id)
  const no_of_images_per_flyers = useSelector(state=> state.planDetails.no_of_images_per_flyers)
  const plan_end_date = useSelector(state => state.planDetails.end_date)
  const getPlanDuration =(end_date)=> {
    var dt1 = new Date();
    var dt2 = new Date(end_date);
    
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
    }
  

  const navigate = useNavigate()
  //single image
  const [image_thumbnail, setImage_thumbnail] = useState('');
   //single image error
  const [error_image_thumbnail, setError_Image_thumbnail] = useState('');

  //mulipile image and error
  const [image_multiple_flyer, setImage_multiple_flyer] = useState('');
  const [error_image_multiple_flyer, setError_Image_multiple_flyer] = useState('');
  
  //common form error
  
  const [addNewFlyerFormError, setAddNewFlyerFormError] = useState('');

  const image_thumbnailChange =(e)=>
  {


    if(e.target.files[0].size > 2097152){
      setError_Image_thumbnail('Maximum Size Limit Is 2 MB , Please Choose Small Size');
    }
    else
    {
    setImage_thumbnail(e.target.files[0]);
    setError_Image_thumbnail('');
    setAddNewFlyerFormError('')

    }



  }


  const image_multiple_flyerChange =(e)=>
  {
    var isError =false
    if(e.target.files.length>no_of_images_per_flyers)
    {
      isError=true;
      setError_Image_multiple_flyer(`Maximum ${no_of_images_per_flyers} Images Only`)
    }

    if(e.target.files.length<=no_of_images_per_flyers)
    {
      for(let i=0;i<e.target.files.length;i++)
      {
        if(e.target.files[i].size > 2097152){
          setError_Image_multiple_flyer('Maximum Size Limit Is 2 MB , Please Choose Small Size');
          isError=true;
          break;
        }
      }
    }

    if(isError===false)
    {
      setImage_multiple_flyer(e.target.files);
      setError_Image_multiple_flyer('')
      setAddNewFlyerFormError('')
    }

  }



  const checkImageUploaded =()=>
  {
 
    
    if(!image_thumbnail)
    {
      setError_Image_thumbnail('Please choose one thumbnail image')
    }

    if(!image_multiple_flyer || image_multiple_flyer.length===0 )
    {
      setError_Image_multiple_flyer('Please choose atleast on additional image')
    }


  }
  


  const {control, setValue,register,handleSubmit, formState: { errors },} = useForm({mode: "all", });
  const onSubmit=(data)=>{

    setIsLoading(true)

    if(error_image_thumbnail==='' && error_image_multiple_flyer==='')
    {
      const formData = new FormData();
      formData.append('plan_id',anyPlan_plan_id)
      formData.append('designed_by',data.flyerDesignedBy.value)
      formData.append('title',data.flyerTitle)
      formData.append('duration',planDuration)
      formData.append('description',data.flyerDiscription)
      formData.append('thumb_image',image_thumbnail)
  
      for(let i=0;i<image_multiple_flyer.length;i++){
        formData.append('flyers[]',image_multiple_flyer[i])
      }
  
      const promise1 = new Promise((resolve, reject) => {
        resolve( addNewFLyer(formData) );
      });
      
      promise1.then((response) => {
  
        if(response.status)
        {
          alert(response.Message);
          setIsLoading(false)
          navigate('/')
          
        }
        else
        {
          alert("You Have Reached Maximum Flyer Upload As Per Your Plan");
          setIsLoading(false);
          setAddNewFlyerFormError("You Have Reached Maximum Flyer Upload As Per Your Plan")
        }

  
      });

    }
    else
    {
      setAddNewFlyerFormError('Please choose images first')
    }
 




    }


  const addNewFLyer=async(formdata)=>{
          
    try {
        const res = await axios.post(baseURL+addNewFlyerURL ,
        formdata, { headers: { "Authorization" : `Bearer ${access_token}`,"content-type": "multipart/form-data" } 
      })

      if(res.data.success===true)
        {
            return({'status': true,'Message':'Flyer Added Successfully ! All flyer will be activated within a time limit of 1 hour.'})
        }
      else if (res.data.success===false)
        {
              return({'status': false,'Message':res.data.message})
        }
      else
        {
            return({'status': false,'Message':'Alert ! Flyer Not Added Try Again Later'})
        }
    } 
    catch (error) {

         return({'status': false,'Message':error.message})
    }
    }

// get all desinged by designers

const [optionDesignedBy,setOptionDesignedBy]=useState([])
const getDesignedByURL='api/vendor/designed/by/list'
const getDesignedBy=async()=>{
    try {
        const res = await axios.get(baseURL+getDesignedByURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {
            //got all the states list here
            setOptionDesignedBy([])
            res.data.data.map((designedBy)=>( setOptionDesignedBy(optionDesignedBy=>[...optionDesignedBy,{value:designedBy.id, label:designedBy.name+'('+designedBy.description+')'}])   ))
        }
        else
        {
            alert("Error Contact Administrator ! Unable to find Designed by list")
        }
    } catch (error) {
        return( error)
    }
    }


useEffect(() => {

  getDesignedBy()
  

  }, [])
  
  const handleCloseNavMenu = () => {
    
  };

  return (
    <>
     <div className={isLoading ? 'parentDisableCommon' : ''} width="100%">

     <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{zIndex:1400,position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />

    </div>

    <div className='container-fluid'>
      <div className='row d-flex justify-content-center'>

          <div className='col-3 d-none d-xl-block d-lg-block'>
          <GeneralMenu closeNavMenuFunction={handleCloseNavMenu} />
          <CopyRightAndTerms/>
          </div>
          <>
          <div className='col-xl-6 col-lg-6 col-10 container-fluid mt-4'>
            
            <div className='row d-flex justify-content-between'>
            <a href={flyerDetailsPDFFILE} download><button className='btn btn-dark'> <p className='m-0 p-0 ' style={{fontSize:'0.7rem'}}>Download Flyer Image Guidlines</p> </button></a>
            <div className='col-xl-5 '>
              
              <div className='row'>
                  <div className='container shadow rounded p-5 text-center mt-3 '>
                    <label style={{cursor:'pointer'}} htmlFor='image_thumbnail'> <CloudArrowUpFill size={36} color='#6c757d'/> 
                    
                    <p className='text-break ' style={{fontWeight:"200",fontSize:'0.9rem'}}>Upload Thumbnail Image (Main Image)</p>
                    <span className='btn btn-primary px-4'    >+ Add Thumbnail</span>
                    <p className='text-break ' style={{fontWeight:"400",fontSize:'0.8rem'}}>Width: 827px - Height: 1170px </p>
                    </label>
                    <input type='file' accept=".png, .jpg, .jpeg" name='image_thumbnail' id='image_thumbnail' className='d-none' onChange={(e)=>image_thumbnailChange(e)} />
                    

                    {/* Image uploaded */}
                    {image_thumbnail?
                    <div className="card mt-2" style={{fontSize:'0.8rem'}}>
                        <div className="card-header">
                          Uploaded Thumbnail
                        </div>
                        <ul className="list-group list-group-flush" style={{fontSize:'0.8rem'}}>
                          <li className="list-group-item">{image_thumbnail.name}</li>
                        </ul>
                      </div>:null}

                      
                    {/* error */}

                    {error_image_thumbnail?<><p style={{color:'red',fontSize:'0.8rem'}}> {error_image_thumbnail} </p></>:null}
                  </div>

                  <div className='container shadow rounded p-5 text-center mt-3'>
                  <label style={{cursor:'pointer'}} htmlFor='image_multiple_flyer'> <CloudPlusFill size={36} color='#6c757d'/>
                    <p className='text-break ' style={{fontWeight:"200",fontSize:'0.9rem'}}>Upload Additonal images</p>
                    <span className='btn btn-primary px-4'>+ Add Additonal Images</span>
                    <p className='text-break ' style={{fontWeight:"400",fontSize:'0.8rem'}}>Width: 827px - Height: 1170px </p>
                    </label>
                    <input type='file' accept=".png, .jpg, .jpeg" name='image_multiple_flyer' id='image_multiple_flyer' className='d-none' multiple  onChange={(e)=>image_multiple_flyerChange(e)}/>
                    


                     {/*Multiple  Image uploaded */}
                    {image_multiple_flyer?
                    <div className="card mt-2" style={{fontSize:'0.8rem'}}>
                        <div className="card-header">
                          Additional Images
                        </div>
                        <ul className="list-group list-group-flush" style={{fontSize:'0.8rem'}}>

                        {(() => {
                        let list = [];
                        for (let i = 0; i <image_multiple_flyer.length; i++) {
                          list.push(<li key={i} className="list-group-item">{image_multiple_flyer[i].name}</li>);
                          }
                        return list;
                          })()}
                         
                        </ul>
                      </div>:null}



                    {error_image_multiple_flyer?<><p style={{color:'red',fontSize:'0.8rem'}}> {error_image_multiple_flyer} </p></>:null}
                  </div>

              </div>
              
            </div>


            <div className='col-xl-6 me-2'>
                <div className='row'>

  {/* flyer upload form */}
             
  <form onSubmit={handleSubmit(onSubmit)} >
                    <div className='container shadow rounded p-4 mt-3 '>
                      <p className='text-break ' style={{fontWeight:"600",fontSize:'0.9rem'}}>Create Flyer Details</p>

                          <div className="form-group mt-2 col-12 ">
                            <label className='mb-1 formLabelNew' >Flyer Title</label>
                            <input type="text" className="form-control rounded-0  formInputTxt" placeholder="Flyer Title" 
                                          {...register("flyerTitle", {
                                            required: "Please Enter Flyer Title",
                                            minLength: {
                                              value: 3,
                                              message: "Flyer Title must be atleast 3 characters long...",
                                            },
                                            maxLength: {
                                              value: 40,
                                              message: "Flyer Title  must be atmost 40 characters long...",
                                            },
                                          })}
                                          />
                            <p className='mt-1 formErrorNew' >{errors.flyerTitle?.message}</p>
                          </div>

                          <div className="form-group mt-2 col-xl-12 col-lg-12 col-md-12  col-sm-12 ">
                            <label className='mb-1 formLabelNew' >Description</label>
                            <textarea type="text" className="form-control rounded-0  formInputTxt" placeholder="Discription" 
                                          {...register("flyerDiscription", {
                                            required: "Please Enter Flyer Discription",
                                            minLength: {
                                              value: 3,
                                              message: "Flyer Discription must be atleast 3 characters long...",
                                            },
                                            maxLength: {
                                              value: 300,
                                              message: "Flyer Discription must be atmost 300 characters long...",
                                            },
                                          })}
                                          />
                             <p className='mt-1 formErrorNew' >{errors.flyerDiscription?.message}</p>
                          </div>
                      

{/* //////////////////////////////////////// */}

                          <div className="form-group mt-2 col-xl-12 col-lg-12 col-md-12  col-sm-12 ">
                            <label className='mb-1 formLabelNew' >Designed by</label>                         


                              <Controller
                                        control={control}
                                        name="flyerDesignedBy"
                                        rules={{ required: "Please Choose Designer" }}
                                        render={({ field: { onChange, value } }) => (
                                          <Autocomplete
                                            onChange={(event, flyerDesignedBy) => {
                                              onChange(flyerDesignedBy);
                                            }}
                                            value={value?value:null}
                                            options={optionDesignedBy}
                                            getOptionLabel={(flyerDesignedBy) => (flyerDesignedBy.label ? flyerDesignedBy.label : "")}
                                            isOptionEqualToValue={(option, value) =>
                                              value === undefined || value === "" || option.id === value.id
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Choose Designer"
                                                size="small"
                                              />
                                            )}
                                          />
                                        )}
                                      />


                           <p className='mt-1 formErrorNew' >{errors.flyerDesignedBy?.message}</p>
                          </div>





                          <div className="form-group mt-2 col-xl-12 col-lg-12 col-md-12  col-sm-12 ">
                            <label className='mb-1 formLabelNew' >Duration Of Flyer</label>
                            <div className='row'>
                              <div className='col-2'>
                              <RangeSlider
                              value={planDuration} min={1} max={getPlanDuration(plan_end_date)} size={'lg'} tooltip={'off'}
                              onChange={changeEvent => setPlanDuration(changeEvent.target.value)}
                            />
                              </div>

                              <div className='col-12'>
                              <input type='number' min="1"  value={planDuration} onChange={changeEvent => setPlanDuration(changeEvent.target.value)} max={getPlanDuration(plan_end_date)}  />
                              </div>

                           
                            </div>
                            <div className='row '>
                              <p>Days </p>

                            </div>
                          </div>


                          

                          <div className="row"> 
                            <div className='col-6'>
                                <button  className="btn btn-outline-secondary" >Cancel</button>
                            </div>
                            <div className='col-6'>
                               <button type="submit" className="form-control  btn btn-primary"  onClick={checkImageUploaded }>Upload Flyer</button>
                               {addNewFlyerFormError?<><p className='mt-1 formErrorNew'>{addNewFlyerFormError}</p></>:null}
                            </div>

                       

                          </div>
                         
                    </div> </form>
                </div>
              </div>
            </div>

            </div>
          
          </>
         

      </div>
    </div>

    
    </>
  )
}

export default AddNewFlyer