import React from 'react'
import './PaymentConfirmation.css'
import  {CheckCircleFill,XCircleFill} from 'react-bootstrap-icons';
import { useLocation ,useNavigate} from 'react-router-dom';

function PaymentConfirmation() {

const navigate =useNavigate();
const { state } = useLocation();


return (
    <>
<div className="container">
   <div className="row">
      <div className="col-md-6 mx-auto mt-5 ">
         <div className="payment shadow">
            <div className="payment_header">
               <div className="check">{state.paymentStatus?<CheckCircleFill size={48} color="#fff"/> :<XCircleFill size={48} color="#fff"/> }  </div>
            </div>
            <div className="content p-3">
            {state.paymentStatus? 
           <> <h3 style={{color:"green" }} >Payment Success !</h3> 
            <p> Thank you for your transfer</p></>
            :
            <> <h3 style={{color:"red" }} >Sorry ! Payment Failed !</h3>  <p> If Cash Debited From Your Account Please Contact Admin With Payment ID </p> </>}

         <p  className="text-uppercase " style={{fontSize:'0.9em'}} > Payment ID : {state.paymentID} </p>

               <button className='btn btn-primary mb-3' onClick={(e)=>{navigate('/')}}> Go Home </button>
            </div>
            
         </div>
      </div>
   </div>
</div>
    
    </>
  )
}

export default PaymentConfirmation