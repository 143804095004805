import React, { useState } from 'react'
import NavBar from '../../NavBar/NavBar'
import {logoPngImg} from '../../../Config.js'
import { Link, useNavigate } from 'react-router-dom';
import './ChangeToVendor.css'
import { useForm } from "react-hook-form";
import {baseURL} from '../../../Config.js';
import axios from 'axios';

function ChangeToVendor() {

const changeCustomerToVendorURL = 'api/customer/to/vendor'
//navigate to
const navigate = useNavigate();


const [showOtp, setShowOtp] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [showError, setShowError] = useState(false);
const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "all",
      });


const onSubmit = (data) => {

 

    //first section to show otp section - changeUserTypePre
    const changeUserTypePre = async () => 
    {
    try {
        const response = await axios.post(baseURL+changeCustomerToVendorURL,{
            mobile: data.mobile,
            otp:data.otp,
        });
      

        if(response.data.success===true)
        {
           
            setShowOtp(true);
        }
        else
        {
            navigate('/login')
        }

        }
    catch(error){
        console.log(error);
    }
    }


        //second section to login the user  - changeUserTypeNext
        const changeUserTypeNext = async () => 
        {
        try {
            const response = await axios.post(baseURL+changeCustomerToVendorURL,{
                mobile: data.mobile,
                otp:data.otp,
            });
          
            if(response.data.success===true)
            {
                
                alert("User Type Changed Successfully , Please Login Again To Continue")
                navigate('/login')
            }
            else
            {
                
                setErrorMessage(response.data.message);
                setShowError(true);

            }
    
            }
        catch(error){
            console.log(error);
        }
        }




    if(showOtp)
    {
        changeUserTypeNext()
    }
    else
    {
        changeUserTypePre()
    }
    



}

     
  return (


    <>
     <NavBar/>
     <div  className="d-flex flex-column min-vh-100 justify-content-center align-items-center" 
          style={{backgroundColor: "#eeeeee"}  }>

      <div className="container col-xl-10 col-lg-10 col-12 bg-light shadow p-3 mb-5 bg-body rounded">

      <div className="row p-5 align-items-center  ">
                <div className="col-xl-5 col-md-6 col-lg-6  col-12"><img src={logoPngImg} style={{maxWidth: "100%",maxHeight: "100%"}} alt="logo" /></div>
                <div className="col-xl-7 col-md-6 col-lg-6  col-12">


<div className='d-flex flex-row align-items-end '>
<h2  className='mb-0' style={{fontWeight:"700", color:"#676767",fontSize:"1.9rem"}}  >Register As A Vendor</h2> 
</div>

<div className='mt-3'>

<form 
onSubmit=
{handleSubmit(onSubmit)}
>


  {/* mobile */}
  <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Mobile Number</label>
      <div className="form-group has-icon mb-2">
      <span className="form-control-feedback">+91</span>
      <input
         onKeyPress={(event) => {
            setShowOtp(false); setShowError(false);
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        maxLength={10}
        className="form-control rounded-0 paddingonLoginandSignUp" 
      {...register("mobile", {
        required: "mobile is Required...",
        minLength: {
          value: 10,
          message: "Please Enter 10 Digit Mobile Number",
        },
       
      })}
      placeholder="xxx - xx - xxx"
    />
  <p className='m-0' style={{color:"red"}}>{errors.mobile?.message}</p>
  
  </div>

{showOtp?
 //otp
  <>
 <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Enter OTP</label>
 <div className="form-group has-icon mb-2">
 <span className="form-control-feedback">OTP</span>
 <input
   maxLength={6}
   onKeyPress={ (e)=>{ 
    setShowError(false); 
    if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
 }}
   className="form-control rounded-0 paddingonLoginandSignUp" 

 {...register("otp", {
   required: "Please Enter OTP",
   minLength: {
     value: 6,
     message: "Enter 6 Digit OTP",
   },
  
 })}

 placeholder="_ _ _ _ _ _"
/>
<p className='m-0' style={{color:"red"}}>
    {errors.otp?.message}
    </p>

</div>
</>:null}

{showError?<span style={{color:"red"}}> {errorMessage} </span>:null}


<input type='submit' className='btn btn-primary btn-lg w-100 rounded-0 p-2 shadow ' value="Register As A Vendor" />
</form> 



</div>
<div className="mt-2">

<span >Alredy a Vendor ?  <Link to="/login" > Login Here  </Link>   </span>
</div>


</div>









          
      </div>


      </div>
  </div>
    </>

   
  )
}

export default ChangeToVendor