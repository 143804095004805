import React ,{useState,useEffect}from 'react'
import GeneralMenu from '../Home/GeneralMenu/GeneralMenu'
import NewUserPlanContainer from '../Home/NewUserPlanContainer/NewUserPlanContainer'
import {useSelector,useDispatch} from "react-redux"
import axios from 'axios'
import {baseURL} from '../../Config';
import HashLoader from "react-spinners/HashLoader";
import {set_isLoading} from "../../Redux/isLoading/isLoadingSlice"
import PlanHistory from './PlanHistory'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CopyRightAndTerms from '../CopyRightAndTerms/CopyRightAndTerms'

function MyPlans() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleCloseNavMenu = () => {
    
  };
  const dispatch =useDispatch()
  const anyPlan_plan_id = useSelector(state => state.userDetails.anyPlan_plan_id)
  const planName = useSelector(state => state.planDetails.name)
  const plan_real_end_date = useSelector(state => state.planDetails.real_end_date)
  const plan_real_start_date = useSelector(state => state.planDetails.real_start_date)

  

  const plan_paymentAprovalByAdmin = useSelector(state => state.planDetails.payment_approval)
  const plan_payment_status_byuser = useSelector(state => state.planDetails.payment_status)
  const plan_vendor_plan_status = useSelector(state => state.planDetails.vendor_plan_status)

  
  const isLoading = useSelector(state => state.isLoading.isLoading);

  const makeUserPlanActiveURL='api/vendor/plan/activate'
  const access_token = JSON.parse(localStorage.getItem('access_token'));



  const makeUserPlanActive =async()=>
  {
    try {
    const res = await axios.post(baseURL+makeUserPlanActiveURL ,{}, { headers: {"Authorization" : `Bearer ${access_token}`} })
    if(res.data.success===true)
      {
        window.location.reload(false);
      }
    else
      {
        alert("Contact Admininstrator")
      }
  } 
  catch (error) {

    alert("Contact Admininstrator")

  }


  }
  const [planHistoryDetails, setPlanHistoryDetails] = useState(null)
  const getPlanHistoryDetailsURL='api/vendor/plan/history'
  const getPlanHistoryDetails=async()=>{
    dispatch(set_isLoading({isLoading:true}))
    try {
        const res = await axios.get(baseURL+getPlanHistoryDetailsURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {
            //got all the states list here
            dispatch(set_isLoading({isLoading:false}))
            setPlanHistoryDetails(res.data.data)
        }
        else{
            alert("Error Contact Administrator")
        }
    } catch (error) {
        return( error)
    }
    }


  useEffect(() => {
  
    getPlanHistoryDetails();

  }, [])




  return (
    <>
        {isLoading?<div className={isLoading ? 'parentDisableCommon' : ''} width="100%">
      <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
    </div>:null}

    <div className='container-fluid'>
    <div className='row d-flex justify-content-center'>

        <div className='col-3 d-none d-xl-block d-lg-block'>
        <GeneralMenu closeNavMenuFunction={handleCloseNavMenu} />
        <CopyRightAndTerms/>
        </div>

        {planHistoryDetails?(planHistoryDetails.length===0)?
        // new user
      <> 
        <div className='col-9 '>
          <NewUserPlanContainer/>  
        </div>
      </>
      :
      <>
          <div className='col-xl-8 col-lg-8 col-md-12 container-fluid'>      
          <div className='row pt-4 mt-2 text-secondary'>
            {(anyPlan_plan_id===0)?
            <>
              <div className="d-flex justify-content-between ">
                  <div ><h6 style={{fontWeight:"500"}}>Current Plan </h6> </div>
                    </div>
              <div className='row'>
              <div className='mt-3' ><h6 style={{fontWeight:"500",color:'red'}}>  
                You have no active plan , Please recharge to continue </h6> </div>
                  </div>
            </>
            :
            <>
              <div className="d-flex justify-content-between ">
                    <div ><h6 style={{fontWeight:"500"}}>Current Plan </h6> </div>
                      </div>
                  <div className='row'>
                    <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Plan Name</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Plan Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>{planName}</td>
                        <td>{plan_real_start_date} - {plan_real_end_date}</td>
                        <td>{(plan_paymentAprovalByAdmin==='inactive' && plan_payment_status_byuser==='paid')?<p style={{color:'orange'}}>Approval Pending</p>:<p style={{color:'green'}}>Paid</p>}</td>

                        <td>{
                            (plan_paymentAprovalByAdmin==='inactive' && plan_vendor_plan_status==='inactive')?<p style={{color:'orange'}}>pending</p>:
                            (plan_paymentAprovalByAdmin==='active' && plan_vendor_plan_status==='inactive')?<><button className='btn btn-danger' onClick={handleShow}>Activate Plan</button></>:
                            (plan_paymentAprovalByAdmin==='active' && plan_vendor_plan_status==='active')?<><p style={{color:'green'}}>Active</p></>:
                              <>-</>
                            }</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>


            </>}

                  <div className="d-flex justify-content-between mt-4">
                    <div ><h6 style={{fontWeight:"500"}}>Plan History </h6> </div>
                      </div>
                  <div className='row'>
                    <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Plan Name</th>
                        <th scope="col">Duration</th>
                        <th scope="col">order_id</th>
                        <th scope="col">Plan Status</th>
                      </tr>
                    </thead>
                    <tbody>
                            <PlanHistory planHistoryDetails={planHistoryDetails} />  

                    </tbody>
                  </table>
                  </div>

          
      </div>   
    

          
          </div>
        
        </>
        :null}



       

    </div>
  </div>

  <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Activate Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are You sure do you want to activate {planName} plan , After Clicking activate the plan will start , and not able to pause
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={makeUserPlanActive}>Activate </Button>
        </Modal.Footer>
      </Modal>

  </>
  )
}

export default MyPlans