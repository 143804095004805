import axios from 'axios';
import React from 'react'
import { Check2 } from 'react-bootstrap-icons';
import {useSelector} from "react-redux"
import {  useNavigate } from 'react-router-dom';
import {baseURL} from '../../../../Config';

function NewUserPlan({planDetails,setcolor}) {

const navigate = useNavigate()
const details = useSelector(state => state.userDetails.details)
const admin_approved_vendor = useSelector(state => state.userDetails.admin_approved_vendor)
const access_token = JSON.parse(localStorage.getItem('access_token'));

const getPaymentLinkURL='api/vendor/plan/payment'


const startPayment=(plan_id)=>
{
    
    const promise1 = new Promise((resolve, reject) => {
        resolve( getPaymentLink(plan_id) );
      });
      
    //   payment url
    promise1.then((paymentLink) => {

        if(paymentLink.status)
        {
            localStorage.setItem('buyerTempID', JSON.stringify(paymentLink.buyerTempID));
            window.open(paymentLink.Url);
        }
        else
        {
            alert( paymentLink.Url)
        }

      });

}


 //get payment link 
 const getPaymentLink=async(plan_id)=>{
    

    try {
        const res = await axios.post(baseURL+getPaymentLinkURL ,{plan_id:plan_id}, { headers: {"Authorization" : `Bearer ${access_token}`} })
   
        if(res.data.success===true)
        {
            const ORDER_ID = res.data.payment_request.buyer_name.split('-') 
            return({'status': true,'Url':res.data.payment_request.longurl ,'buyerTempID':ORDER_ID[1]})
        }
        else if (res.data.success===false)
        {
            return({'status': false,'Url':res.data.message.email,'buyerTempID':null})
        }
        else
        {
            return({'status': false,'Url':'Please Contact Administrator','buyerTempID':null})
        }

    } catch (error) {
        return({'status': false,'Url':error.message})
    }

  }







const choosePlan=(plan_id)=>{ 

    //  checking user details verified or not 
     if(details===0)
     {
      
        navigate('/fill-business-details');
     }
     else if(details===1)
     {
        //  checking vendor approved by admin
        if(admin_approved_vendor==="0")
        {
            alert("Sorry user not activated by admin , Activation May Take Upto 1 Hour, Please Wait")
        }
        else if (admin_approved_vendor==="1" )
        {
           
            startPayment(plan_id);
        }
        else
        {
            alert("contact admin")
        }

       

     }
    }

  return (
    <>


  
        <div className="col-xl-3 col-lg-5 col-md-8 m-2  pb-5 " style={{borderRadius: "0.8rem",backgroundColor:setcolor?"#e0e0e0":'#0d6efd' ,color: setcolor?'#000':'#fff' }}>

        {/* basic plan row */}
        <div className='row'>
                <div className="d-flex justify-content-between mt-3">
                    <div >{planDetails.name}</div>
                    <div> <h3>₹{planDetails.mrp}</h3><h6>-{planDetails.duration*30} Days </h6></div>
                </div>
            </div>

        {/* horziondal line row */}
        <div className='row'>
                    <h6>____</h6>
                    </div>
          
        {/* Plan Includes*/}
           <div className='row mt-3'>
                <h6 style={{fontWeight: "600"}}>Plans Includes :</h6>
            </div>

        {/* Plan Hihlihths*/}
             <div className='row mt-3 mb-3'>
                {planDetails.highlights.map( 
                    (highlight,index)=>{ return(
                        <div key={index} className='col-12 mt-2 '>
                            <Check2/> <h6 style={{fontSize: "0.8rem",fontWeight: "400"}}>{highlight.highlights}</h6>
                        </div>
                    )
            })}   
            </div>  


        {/* View all featrues*/}
        <div className='row mt-5 text-center'>
                <h6  style={{fontSize: "0.8rem",fontWeight: "500", color: setcolor?'#0d6efd':'#fff' }}>View all the featrues</h6>
            </div>


        {/* View all featrues*/}
        <div className='row mt-3 text-center mx-2'>

        <button className= {setcolor?'btn btn-primary':'btn btn-light'    }     name={planDetails.name} value={planDetails.id}  onClick={ (e)=>{  choosePlan(e.target.value) }    } >Start {planDetails.name} Plan</button>

        </div>

            
            
        </div>
 
   
    
    

        
    </>

  )
}

export default NewUserPlan