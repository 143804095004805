import React from 'react'
import {useSelector} from "react-redux"
import GeneralMenu from './GeneralMenu/GeneralMenu';
import NewUserPlanContainer from './NewUserPlanContainer/NewUserPlanContainer';
import OldUserMiddleContainer from './OldUserMiddleContainer/OldUserMiddleContainer';
import OldUserShowPlanSection from './OldUserShowPlanSection/OldUserShowPlanSection';
import HashLoader from "react-spinners/HashLoader";
import CopyRightAndTerms from '../CopyRightAndTerms/CopyRightAndTerms';
function Home() {

const isOldUser = useSelector(state => state.userDetails.isOldUser)
const isLoading = useSelector(state => state.isLoading.isLoading)

const handleCloseNavMenu = () => {
    
};


 
  return (
    <>
  <div className={isLoading ? 'parentDisableCommon' : ''} width="100%">

  <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />

</div>
    <div className='container-fluid'>
      <div className='row d-flex justify-content-center'>

          <div className='col-3 d-none d-xl-block d-lg-block'>
             <GeneralMenu closeNavMenuFunction={handleCloseNavMenu} />
             <CopyRightAndTerms/>
          </div>

          {isOldUser?
          <>
          <div className='col-xl-9 col-lg-9 col-md-12 container-fluid'>
            <OldUserMiddleContainer/>
            </div>
          {/* <div className='col-3  container-fluid bg-light d-none d-xl-block d-lg-block'><OldUserShowPlanSection/></div> */}
          </>:
          <div className='col-9 '>
              <NewUserPlanContainer/>  
          </div>}

      </div>
    </div>

    
    </>
    
  )
}

export default Home