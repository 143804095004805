import {logoBlue,businessImg,logo} from '../../Config'
import React ,{useState,useEffect}from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import CopyRightAndTerms from '../CopyRightAndTerms/CopyRightAndTerms';

function NavBar() {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {

  };

  const handleCloseNavMenu = () => {
        setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {

  };
  return (

   
<>

<AppBar position="sticky" style={{ background: '#0d6efd' ,zIndex:1000 }} >
                        <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography 
                                variant="h6"
                                noWrap
                                sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                                           
                                }}
                                >
                              <img src={logo} alt={'logo'} style={{maxWidth:'150px'}}/>
                              
                              
                                </Typography>
                        
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="blue"
                                >
                                <MenuIcon />
                                </IconButton>
                                <Menu
                                className='col-10 col-sm-6 col-md-6 col-lg-6 col-xl-6'
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                        display: { xs: 'block', md: 'none' },
                                }}
                                >
                                <div key={4} className='col-8 m-auto' >
                                        <h6 style={{fontSize:'0.6rem', color:'red'}}>Please Login To Continue</h6>
                                        <CopyRightAndTerms/>
                                        
                                       
                                </div>

                                </Menu>
                                </Box>
                                <Typography 
                                variant="h5"
                                noWrap
                                component="a"
                                sx={{
                               
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                               
                               
                                }}
                                >
                                <img src={logo} alt={'logo'} style={{maxWidth:'150px'}}/>

                                </Typography>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                       
                                </Box>

                       
                                <Box sx={{ flexGrow: 0 }}>
                                
                               
                                <Tooltip title="Open settings">
                                        
                                <IconButton  sx={{ p: 0 }}>
                                        
                                </IconButton>
                                </Tooltip>
                                <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                >
                                <MenuItem key={1} >
                                        {/* <Typography textAlign="center" onClick={handleLogOut}>Logout</Typography> */}
                                </MenuItem>     
                                </Menu>
                                </Box>
                                </Toolbar>
                        </Container>
                        </AppBar>


    
</>

  )

}

export default NavBar