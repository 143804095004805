import {createSlice } from "@reduxjs/toolkit"

export const userDetailsSlice= createSlice ({
    name : "userDetails",
    initialState :

 {
     isOldUser:null,
     details:null,
     admin_approved_vendor:null,
     admin_approved_vendor_payment:null,
     anyPlan_plan_id:null,
     business_logo:null   
 }
    ,

    reducers:{

        // details - 0 is user details not given ,details - 1 is user details given
        set_user_details_details : (state,{payload})=>{
            state.details=payload.details
        },

        //isOldUser - true is old user , else new user
        set_user_details_isOldUser : (state,{payload})=>{
            state.isOldUser=payload.isOldUser
        },

        set_user_details_admin_approved_vendor : (state,{payload})=>{
            state.admin_approved_vendor=payload.admin_approved_vendor
        },

        set_user_details_admin_approved_vendor_payment : (state,{payload})=>{
            state.admin_approved_vendor_payment=payload.admin_approved_vendor_payment
        },

        set_user_details_business_logo : (state,{payload})=>{
            state.business_logo=payload.business_logo
        },


        //zero means no plan yet , 1 means some plan
        set_user_details_anyPlan_plan_id : (state,{payload})=>{
            state.anyPlan_plan_id=payload.anyPlan_plan_id
        },



        


        remove_user_details : (state)=>{
            state.isOldUser=null
            state.details=null
            state.admin_approved_vendor=null
            state.admin_approved_vendor_payment=null
            state.anyPlan_plan_id=null
            state.business_logo=null
        },

    }
})

export const { set_user_details_business_logo,set_user_details_anyPlan_plan_id,set_user_details_details,set_user_details_plan_id,set_user_details_admin_approved_vendor,remove_user_details,set_user_details_isOldUser,set_user_details_admin_approved_vendor_payment} = userDetailsSlice.actions

export default userDetailsSlice.reducer