import React from 'react'
import  {Headset} from 'react-bootstrap-icons';
function CopyRightAndTerms() {
  return (

<>
    <p className='text-secondary text-center mt-1 text-break m-0' style={{fontSize:'0.8rem'}}>Copyright © 2023 IWILLFLY  |  Terms and Conditions |
    </p>
    <p className='text-secondary text-center  text-break m-0' style={{fontSize:'0.8rem'}}>
      <a href="tel:+918129654111" className="ms-1 text-decoration-none">
      <Headset/> Call Us +91 8129 654 111 </a> | <a href="mailto:support@iwillfly.com?subject=Contact Us" className="ms-1">
      Contact Us
      
      </a> 
    </p>
    <p className='text-secondary text-center text-break m-0' style={{fontSize:'0.8rem'}}>
      
    </p>
   
   
   

    
    </>
    

  )
}

export default CopyRightAndTerms