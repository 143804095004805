import React ,{useState,useEffect}from 'react'
import {useSelector} from "react-redux"
function PlanHistory({planHistoryDetails}) {

    const plan_order_id = useSelector( state=> state.planDetails.order_id )
    const [filteredUniquePlanHistory, setFilteredUniquePlanHistory] = useState(null)

useEffect(() => {
  
    let UniquePlanHistory = planHistoryDetails.filter( (ele, ind) => ind === planHistoryDetails.findIndex( elem => elem.order_id === ele.order_id ))

    const filteredUniquePlanHistoryArray = UniquePlanHistory.filter(plan => {
      return plan.order_id !== plan_order_id;
    });
    
    setFilteredUniquePlanHistory(filteredUniquePlanHistoryArray)

    
      }, [planHistoryDetails])

  return (
    <>
{filteredUniquePlanHistory?filteredUniquePlanHistory.length!==0?
<>
{filteredUniquePlanHistory.map((plan,index)=>
(
<>
<tr key={1}>
<th scope="row" key={index}>{index+1} </th>
<td>{plan.plan_name}</td>
<td>{plan.start_date} - {plan.real_end_date}</td>
<td>{plan.order_id}</td>
<td>{plan.plan_status}</td>
</tr>
</>
))}
</>
:
<><tr key={2}><th >No Plan History Found</th></tr></>:null}
</>
  )
}

export default PlanHistory