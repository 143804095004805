import React from 'react'

function OldUserShowPlanSection() {
  return (
    <>

    {/* plan status */}
    <div className='row ps-2 mt-2 text-secondary'>
             <div className="d-flex justify-content-start ">
                <div ><h6 style={{fontWeight:"400",color:"green",fontSize:'0.8em'}}> Plan Active </h6> </div>
            </div>
    </div>

    {/* current plan */}
        <div className='row ps-2 mt-2 bg-primary mx-2 shadow'>
            <div className="d-flex justify-content-center ">
            <div className='p-5' ><h6 style={{fontWeight:"400",color:"#fff",fontSize:'0.8em'}}> 99/ Month Plan </h6> </div>
            </div>
        </div>

    {/* more plan */}
        <div className='row ps-2 mt-4 text-secondary'>
             <div className="d-flex justify-content-start ">
                <div ><h6 style={{fontWeight:"400",fontSize:'0.8em'}}> More Plans </h6> </div>
            </div>
        </div>

    {/* plan list */}

        <div className='row ps-2 mt-2 bg-white mx-2 shadow'>
            <div className="d-flex justify-content-center ">
            <div className='p-5' ><h6 style={{fontWeight:"400",color:"#000",fontSize:'0.8em'}}> 129/ Month Plan </h6> </div>
            </div>
        </div>

        <div className='row ps-2 mt-2 bg-white mx-2 shadow'>
            <div className="d-flex justify-content-center ">
            <div className='p-5' ><h6 style={{fontWeight:"400",color:"#000",fontSize:'0.8em'}}> 249/ Month Plan </h6> </div>
            </div>
        </div>

        <div className='row ps-2 mt-2 bg-white mx-2 shadow'>
            <div className="d-flex justify-content-center ">
            <div className='p-5' ><h6 style={{fontWeight:"400",color:"#000",fontSize:'0.8em'}}> 399/ Month Plan </h6> </div>
            </div>
        </div>


    
        </>
  )
}

export default OldUserShowPlanSection