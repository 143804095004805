import React from 'react'
import GeneralMenu from '../Home/GeneralMenu/GeneralMenu'
import {comingSoonImg} from '../../Config';
import CopyRightAndTerms from '../CopyRightAndTerms/CopyRightAndTerms';


function Settings() {

  const handleCloseNavMenu = () => {
    
  };

  return (
    <div className='container-fluid'>
        <div className='row d-flex justify-content-center'>

            <div className='col-3 d-none d-xl-block d-lg-block'>
            <GeneralMenu closeNavMenuFunction={handleCloseNavMenu} />
            <CopyRightAndTerms/>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 container-fluid '>
                    <div className='col-12 d-flex align-items-stretch justify-content-center'>
                        <div className="d-flex flex-column">
                        <div className=""><img src={comingSoonImg} alt={comingSoonImg} style={{maxWidth:'100%',maxHeight:'500px'}}/></div>
                        <div className="text-center"> <h5>Coming Soon !  </h5></div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
          
  )
}

export default Settings