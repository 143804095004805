import logoBlueRse from './assests/images/logoBlueRse.png';
import logowhite from './assests/images/logo.png';
import logoPngImng from './assests/images/loginPageImg.jpg';
import businessimg from './assests/images/businessImage.jpg'
import comingSoonimg from './assests/images/comingSoon.png'
import NoFlyerAddedimg from './assests/images/NoFlyerAdded.jpg'
import flyerDetailsPDF from './assests/images/IWILLFLY_FLYERDETAILS.pdf'





//blue logo
export const  logoBlue = logoBlueRse;
//white logo
export const  logo = logowhite;

//logo png
export const  logoPngImg = logoPngImng;

//businessimg img
export const businessImg = businessimg


//no flyer added img
export const NoFlyerAddedImg = NoFlyerAddedimg


export const flyerDetailsPDFFILE =flyerDetailsPDF

//coming soon 
export const comingSoonImg=comingSoonimg
//base url
export const  baseURL = 'https://admin.iwillfly.in/'

//flyer Image Path 
export const  flyerImagePath = 'https://admin.iwillfly.in/public/storage/flyers/'

//busineess Image Path 
export const  businessImagePath = 'https://admin.iwillfly.in/public/storage/business/'