import React from 'react'
import NewUserPlan from './NewUserPlan/NewUserPlan'
import { useState,useEffect } from 'react'
import {baseURL} from '../../../Config';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

function NewUserPlanContainer() {

  const getPlanDetailsURL='api/vendor/plan';
  const [planDetails, setPlanDetails] = useState();
  const [PlansAvailable, setPlansAvailable] = useState(false);
  const access_token = JSON.parse(localStorage.getItem('access_token'));
  const navigate=useNavigate()

  //Using async await for axios for get all plan details 
  const getPlanDetails=async()=>{

    try {
        const res = await axios.get(baseURL+getPlanDetailsURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {

            if (res.data.data.length !== 0) { setPlansAvailable(true) }

            //got all the plans here
            setPlanDetails(res.data.data);

        }
        else{
            navigate('/login')
        }
    } catch (error) {
        return( error)
    }

  }
  
  useEffect(() => {
   
   getPlanDetails()


  }, [])
  

  return (

    <>
     <div className='mt-2'>
        <div className='row'>
            <h1 style={{fontWeight: "400"}}>Start now</h1>
        </div>
        <div className='row'>
            <h1  style={{fontWeight: "400"}} >Your  <span style={{color: "#0d6efd"}} >FLYERS </span> </h1>
        </div>
       
     </div>

    <div className=''>
        <div className='row row-eq-height d-flex justify-content-center '>

        {/* No plans Available */}
        
        {PlansAvailable?
           planDetails.map((planDetail,index)=>
                    {
                      var setcolor=false
                      if((index%2)===0)
                      {
                         setcolor=true
                      }
                    return(<NewUserPlan  key={index} planDetails={planDetail} setcolor={setcolor}  /> )
                    }
                      )
        :
       
            <div className='p-5 '>
            <h2> Sorry No Plans Available</h2>
            </div>
        }

        

        </div>
       
    </div>

        </>

  )
}

export default NewUserPlanContainer