import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
function CustomerToVendor() {

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  
//navigate to
const navigate = useNavigate();


  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure Do You Want To Register As A Vendor ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         You have already registred as a customer if you want to switch then please re enter otp to change
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            GO Back 
          </Button>
          <Button variant="primary" onClick={()=>{navigate('/vendor-regsiter')}}>Register As Vendor</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CustomerToVendor