import React from 'react'
import  {FolderFill,GearFill,QuestionCircleFill,Power,HouseFill} from 'react-bootstrap-icons';
import {NavLink,useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import {useDispatch} from "react-redux"
import {unauthenticate_user} from "../../../Redux/userAuthentication/userAuthenticationSlice"
function GeneralMenu({closeNavMenuFunction}) {

  const navigate =useNavigate();
  const dispatch =useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogOut = () => {
    setShow(false);
    dispatch(unauthenticate_user());localStorage.clear();
    
    navigate('/login') 

  }
  return (

<>

<div className='container-fluid col-12 col-md-12 col-xl-12 '>
    <div className='row  p-xl-4 mt-xl-2 p-lg-4 mt-lg-2  p-xl-4 mt-xl-2text-secondary'>
    <h6 style={{fontWeight:"300"}}> General menu </h6> 
  </div>

  <div className=' ms-4'>
     <div className='row mb-3 ' onClick={()=>closeNavMenuFunction()}>
        <NavLink  className={({isActive})=> isActive?'d-flex justify-content-between align-items-center text-primary mb-4 p-2 text-decoration-none':'d-flex justify-content-between align-items-center text-secondary mb-4 p-2 text-decoration-none' }  to="/">
            <div className='ms-2 me-1'><HouseFill className='fs-3'  /></div>    
            <div className='me-5'> Home </div>
       </NavLink>

       <NavLink  className={({isActive})=> isActive?'d-flex justify-content-between align-items-center text-primary mb-4 p-2 text-decoration-none':'d-flex justify-content-between align-items-center text-secondary mb-4 p-2 text-decoration-none' }  to="/myplans">
            <div className='ms-2 me-1'><FolderFill className='fs-3'  /></div>
            <div className='me-5'>My Plans</div>
        </NavLink>

        <NavLink  className={({isActive})=> isActive?'d-flex justify-content-between align-items-center text-primary mb-4 p-2 text-decoration-none':'d-flex justify-content-between align-items-center text-secondary mb-4 p-2 text-decoration-none' }  to="/settings">
            <div className='ms-2  me-1'><GearFill className='fs-3'  /></div>
            <div className='me-5'>Settings</div>
        </NavLink>

        <NavLink  className={({isActive})=> isActive?'d-flex justify-content-between align-items-center text-primary mb-4 p-2 text-decoration-none':'d-flex justify-content-between align-items-center text-secondary mb-4 p-2 text-decoration-none' }  to="/help">
            <div className='ms-2 me-1'><QuestionCircleFill className='fs-3'  /></div>
            <div className='me-5'>Help</div>
        </NavLink>

   
        <div  className='d-flex justify-content-between align-items-center text-secondary mb-4 p-2 text-decoration-none ' style={{cursor:'pointer'}} onClick={handleShow} >

            <div className='ms-2 me-1'><Power className='fs-3'  /></div>
            <div className='me-5' >Logout</div>

        </div> 


       
    </div>  
  </div>
  </div>

  <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure , Do You Want To Logout </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLogOut}>
            Log Out
          </Button>
        </Modal.Footer>
      </Modal>
    </>

</>
  )
}

export default GeneralMenu