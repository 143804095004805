import {logoBlue,businessImg,logo, baseURL} from '../../../Config'
import  {List} from 'react-bootstrap-icons';
import {businessImagePath} from '../../../Config';
import React ,{useState,useEffect}from 'react'
import  {GeoAltFill} from 'react-bootstrap-icons';
import {useSelector,useDispatch} from "react-redux"
import { useNavigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import GeneralMenu from '../GeneralMenu/GeneralMenu';
import axios from 'axios';
import CopyRightAndTerms from '../../CopyRightAndTerms/CopyRightAndTerms';

function HomeNavBar() {
    const navigate=useNavigate()
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const dispatch =useDispatch()

    const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    };

    const getPlanDuration =(end_date)=> {
        var dt1 = new Date();
        var dt2 = new Date(end_date);
        
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
        }


    const name = useSelector(state => state.userAuthentication.name)
    const admin_approved_vendor = useSelector(state => state.userDetails.admin_approved_vendor)
    const business_logo = useSelector(state => state.userDetails.business_logo)
    const anyPlan_plan_id = useSelector(state=> state.userDetails.anyPlan_plan_id)
    const admin_approved_vendor_payment = useSelector(state=> state.userDetails.admin_approved_vendor_payment)
    const vendor_plan_status = useSelector(state=> state.planDetails.vendor_plan_status)
    const real_end_date = useSelector(state=> state.planDetails.real_end_date)
    const access_token = JSON.parse(localStorage.getItem('access_token'));


    const [planHistoryDetails, setPlanHistoryDetails] = useState(null)
    const getPlanHistoryDetailsURL='api/vendor/plan/history'
    const getPlanHistoryDetails=async()=>{
//       dispatch(set_isLoading({isLoading:true}))

      try {
          const res = await axios.get(baseURL+getPlanHistoryDetailsURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
          if(res.data.success)
          {
              //got all the states list here
        //       dispatch(set_isLoading({isLoading:false}))
              
              setPlanHistoryDetails(res.data.data)
          }
          else{
              alert("Error Contact Administrator ")
          }
      } catch (error) {
          return( error)
      }
      }
  

useEffect(() => {
  
        getPlanHistoryDetails();
    
    
      }, [])
      
  return (

    <>

                        <AppBar position="sticky" style={{ background: '#fff' ,zIndex:1000 }} >
                        <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography onClick={()=>{navigate('')}}
                                variant="h6"
                                noWrap
                                sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                                           
                                }}
                                >
                              <img src={logoBlue} alt={'logo'} style={{maxWidth:'150px'}}/>
                              
                                </Typography>

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="blue"
                                >
                                <MenuIcon />
                                </IconButton>
                                <Menu
                                className='col-10 col-sm-6 col-md-6 col-lg-6 col-xl-6'
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                        display: { xs: 'block', md: 'none' },
                                }}
                                >
                                <MenuItem key={1} className='' >

                                <div className="mx-2 ">
                                        <h6 className='text-break  text-black' style={{fontSize:'1rem'}}> Hello ! {name}</h6>
                                        {admin_approved_vendor?<> <h6 className='text-break  text-black' style={{fontSize:'0.8rem'}}> Vendor Status : {(admin_approved_vendor==='1')?<><span style={{color:'green'}}>Active</span></>:<><span style={{color:'orange'}}>Pending</span></>} </h6>  </> :null   }
                                        
                                        {
                                        planHistoryDetails?
                                        (anyPlan_plan_id===0 && planHistoryDetails.length!==0)?
                                        <h6 className='text-break  text-black' style={{fontSize:'0.8rem'}}> 
                                        Plan Status : <span style={{color:'red'}}>Expired</span></h6>:null:null
                                        } 


                                        {anyPlan_plan_id?
                                        <><h6 className='text-break  text-black' style={{fontSize:'0.8rem'}}> Plan Status :
                                        {admin_approved_vendor_payment==='1'?(vendor_plan_status==='active')?
                                        <span style={{color:'green'}}>Active</span>:
                                        <span style={{color:'brown'}}>InActive</span>:
                                        <span style={{color:'orange'}}>Pending</span>}     
                                        </h6> </>:null } 

                                        {
                                                (real_end_date!==null)?(getPlanDuration(real_end_date)<=3)?<h6 className='text-break  text-danger' style={{fontSize:'0.8rem'}}>Plan Expire In {getPlanDuration(real_end_date)} Days </h6>:null:null
                                        }             
                                </div>
                                    
                                </MenuItem>
                                <MenuItem key={2} >
                                        <GeneralMenu closeNavMenuFunction={handleCloseNavMenu} />
                                      
                                </MenuItem>

                                <div key={4} className='col-8 m-auto' >
                                        
                                        <CopyRightAndTerms/>
                                        
                                       
                                </div>

                                </Menu>
                                </Box>
                                <Typography onClick={()=>{navigate('')}}
                                variant="h5"
                                noWrap
                                component="a"
                                sx={{
                               
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                               
                               
                                }}
                                >
                                <img src={logoBlue} alt={'logo'} style={{maxWidth:'150px'}}/>

                                </Typography>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                        <Typography style={{color:'#f3da09'}}>
                                                     {/* <GeoAltFill/> {locationName?<>{locationName}</>:<>Choose Location</>} */}
                                        </Typography>
                                </Box>

                        <div className="mx-2 py-2 d-none d-xl-block d-lg-block d-md-block ">
                            {admin_approved_vendor?<> <h6 className='text-break  text-black' style={{fontSize:'0.8rem'}}> Vendor Status : {(admin_approved_vendor==='1')?<><span style={{color:'green'}}>Active</span></>:<><span style={{color:'orange'}}>Pending</span></>} </h6>  </> :null   }
                            

            
                            {
                            planHistoryDetails?
                            (anyPlan_plan_id===0 && planHistoryDetails.length!==0)?
                            <h6 className='text-break  text-black' style={{fontSize:'0.8rem'}}> 
                            Plan Status : <span style={{color:'red'}}>Expired</span></h6>:null:null
                            } 

                            {anyPlan_plan_id?
                            
                            <><h6 className='text-break  text-black' style={{fontSize:'0.8rem'}}> Plan Status :
                            {admin_approved_vendor_payment==='1'?(vendor_plan_status==='active')?
                            <span style={{color:'green'}}>Active</span>:
                            <span style={{color:'brown'}}>InActive</span>:
                            <span style={{color:'orange'}}>Pending</span>}     
                            </h6> </>:null } 

                            {
                             (real_end_date!==null)?(getPlanDuration(real_end_date)<=3)?<h6 className='text-break  text-danger' style={{fontSize:'0.8rem'}}>Plan Expire In {getPlanDuration(real_end_date)} Days </h6>:null:null

                            }     
                            
                            </div>
                                <Box sx={{ flexGrow: 0 }}>
                                
                               
                                <Tooltip title="Open settings">
                                        
                                <IconButton  sx={{ p: 0 }}>
                                        <Avatar alt={name} src={businessImagePath+business_logo} />
                                </IconButton>
                                </Tooltip>
                                <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                >
                                <MenuItem key={1} >
                                        {/* <Typography textAlign="center" onClick={handleLogOut}>Logout</Typography> */}
                                </MenuItem>     
                                </Menu>
                                </Box>
                                </Toolbar>
                        </Container>
                        </AppBar>
        </>

  )
}

export default HomeNavBar