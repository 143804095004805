import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes , Route, useNavigate } from 'react-router-dom';
import Home from './Components/Home/Home';
import LoginAndSignupContainer from './Components/LoginAndSignupContainer/LoginAndSignupContainer';
import ChangeToVendor from './Components/LoginAndSignupContainer/ChangeToVendor/ChangeToVendor';
import FillBusinessDetails from './Components/FillBusinessDetails/FillBusinessDetails';
import { useEffect,useState } from 'react';
import {useSelector,useDispatch} from "react-redux"
import {authenticate_user,unauthenticate_user,inisate_user} from "./Redux/userAuthentication/userAuthenticationSlice"
import { set_user_details_business_logo,set_user_details_anyPlan_plan_id,set_user_details_isOldUser,set_user_details_details,set_user_details_admin_approved_vendor,set_user_details_admin_approved_vendor_payment} from "./Redux/userDetails/userDetailsSlice"
import {set_planDetails} from "./Redux/planDetails/planDetailsSlice"
import {set_isLoading} from "./Redux/isLoading/isLoadingSlice"


import {baseURL} from './Config';
import axios from 'axios';
import HomeNavBar from './Components/Home/HomeNavBar/HomeNavBar';
import MyPlans from './Components/MyPlans/MyPlans';
import AddNewFlyer from './Components/AddNewFlyer/AddNewFlyer';
import PaymentSuccess from './Components/PaymentSuccess/PaymentSuccess';
import PaymentConfirmation from './Components/PaymentConfirmation/PaymentConfirmation';
import Settings from './Components/Settings/Settings';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';

function App() {

  const authenticated = useSelector(state => state.userAuthentication.authenticated)
  const details = useSelector(state => state.userDetails.details)
  const getUserDetailsURL = 'api/vendor/details'

  const navigate=useNavigate()
  const dispatch =useDispatch()

  //get access token

  const access_token = JSON.parse(localStorage.getItem('access_token'));


  //checck user status
    const checkUserStatus = async (token) => 

    {
      dispatch(set_isLoading({isLoading:true}))
    try {
        const response = await axios.get(baseURL+getUserDetailsURL,{ headers: {"Authorization" : `Bearer ${token}`} });
       

        if(response.status===200){ 
          if(response.data.status===false)
          {
            alert('Your are Blocked by Administrator , Please Contact Administrator')
            dispatch(unauthenticate_user());localStorage.clear();
            dispatch(set_isLoading({isLoading:false}))
            navigate('/login')

          }

          dispatch( authenticate_user({name:response.data.data.name})  ) ;
          // || response.data.data.vendor_detail.plan_detail===null
          if(response.data.data.vendor_detail===null || response.data.data.vendor_detail.plan_detail===null )
          { 
            
             dispatch( set_user_details_details( {details: response.data.data.details } ));
             dispatch(set_user_details_isOldUser({ isOldUser:false  }));
             dispatch(set_isLoading({isLoading:false}))
             if(response.data.data.vendor_detail!==null){  
               dispatch(set_user_details_admin_approved_vendor({ admin_approved_vendor: response.data.data.vendor_detail.admin_approved_vendor  })  )
               dispatch(set_user_details_admin_approved_vendor_payment({ admin_approved_vendor_payment: response.data.data.vendor_detail.admin_approved_vendor_payment  })  )
               dispatch(set_user_details_anyPlan_plan_id({ anyPlan_plan_id :response.data.data.vendor_detail.plan_id }))
               dispatch( set_user_details_business_logo({ business_logo :response.data.data.vendor_detail.business_logo }))

              
               
               if( response.data.data.vendor_detail.plan_detail !==null  )
               {
                  var plan_detail = response.data.data.vendor_detail.plan_detail;
                  dispatch(set_planDetails( { 
                    plan_id:plan_detail.plan_id ,
                    name:plan_detail.plans.name ,
                    duration:plan_detail.plans.duration, 
                    no_of_flyers:plan_detail.plans.no_of_flyers, 
                    no_of_images_per_flyers:plan_detail.plans.no_of_images_per_flyers,
                    start_date:plan_detail.start_date, 
                    end_date:plan_detail.end_date,
                    real_start_date:plan_detail.real_start_date,
                    real_end_date:plan_detail.real_end_date,
                    payment_status:plan_detail.payment_status, 
                    plan_status:plan_detail.plan_status,
                    vendor_plan_status:plan_detail.vendor_plan_status,
                    payment_approval:plan_detail.payment_approval,
                    order_id:plan_detail.order_id}  ))
                  dispatch(set_isLoading({isLoading:false}))
               }
              
             }

          }
          else
          {

        
            dispatch( set_user_details_details( {details: response.data.data.details } ));
            dispatch(set_user_details_isOldUser({ isOldUser:true  }));
            dispatch(set_user_details_admin_approved_vendor({ admin_approved_vendor: response.data.data.vendor_detail.admin_approved_vendor  }));
            dispatch(set_user_details_admin_approved_vendor_payment({ admin_approved_vendor_payment: response.data.data.vendor_detail.admin_approved_vendor_payment  })  );
            dispatch(set_user_details_anyPlan_plan_id({ anyPlan_plan_id :response.data.data.vendor_detail.plan_id }));
            dispatch( set_user_details_business_logo({ business_logo :response.data.data.vendor_detail.business_logo }))
            dispatch(set_isLoading({isLoading:false}))
            if( response.data.data.vendor_detail.plan_detail !==null  )
            {
               var plan_detail = response.data.data.vendor_detail.plan_detail;
               dispatch(set_planDetails( { 
                 plan_id:plan_detail.plan_id ,
                 name:plan_detail.plans.name ,
                 duration:plan_detail.plans.duration, 
                 no_of_flyers:plan_detail.plans.no_of_flyers,
                 no_of_images_per_flyers:plan_detail.plans.no_of_images_per_flyers, 
                 start_date:plan_detail.start_date, 
                 end_date:plan_detail.end_date,
                 real_start_date:plan_detail.real_start_date,
                 real_end_date:plan_detail.real_end_date,
                 payment_status:plan_detail.payment_status, 
                 plan_status:plan_detail.plan_status,
                 vendor_plan_status:plan_detail.vendor_plan_status,
                 payment_approval:plan_detail.payment_approval,
                 order_id:plan_detail.order_id} ))
             }


          }

        }
        return(response) 
        }
    catch(error){
        dispatch(set_isLoading({isLoading:false}))
        if(error.response.status===401){ dispatch(unauthenticate_user()); localStorage.removeItem('access_token');  }
        else{  console.log(error);   }
        return(error) 
    }
    }

  useEffect(() => {
    console.log("app started")
   checkUserStatus(access_token);
   if (authenticated===false) {
    navigate('/login')
    console.log("unauthenticated");
   }

  }, [authenticated,access_token,details])

  return (

    <>
      {authenticated? <HomeNavBar/>:null}   
      <Routes>
        <Route path='/' element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
        <Route path='/myplans' element={
          <ProtectedRoute>
            <MyPlans />
          </ProtectedRoute>
        } />
        <Route path='/login' element={<LoginAndSignupContainer />} />
        <Route path='/payment_success' element={
            <PaymentSuccess />
        } />
        <Route path='/payment_confirmation' element={
            <PaymentConfirmation />
        } />
        <Route path='/vendor-regsiter' element={
         
            <ChangeToVendor />
         
        } />
        <Route path='/fill-business-details' element={
          <ProtectedRoute>
            <FillBusinessDetails />
          </ProtectedRoute>
        } />
        <Route path='/addnewflyer' element={
          <ProtectedRoute>
            <AddNewFlyer />
          </ProtectedRoute>
        } />
        <Route path='/settings' element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        } />
        <Route path='/help' element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        } />
      </Routes>
    
    
    </>
  );
}

export default App;
